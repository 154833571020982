import React, { useEffect } from "react"
import { connect } from "react-redux"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import SixFeetRendered from "../../components/shop/sixFeetRendered"

const SixFeetPage = ({}) => {
  return (
    <Layout>
      <SEO title="Six Feet" />
      <SixFeetRendered />
    </Layout>
  )
}

export default connect(state => ({}), null)(SixFeetPage)
