import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import Img from "gatsby-image"

import paris_blue from "../../images/shop/sixFeet/parisLogo-blue.svg"
import paris_white from "../../images/shop/sixFeet/parisLogo-white.svg"
import alright_white from "../../images/shop/sixFeet/alrightLogo-white.svg"
import alright_red from "../../images/shop/sixFeet/alrightLogo-red.svg"

import chain from "../../images/shop/sixFeet/chain.png"
import world from "../../images/shop/sixFeet/world.png"
import tree from "../../images/shop/sixFeet/tree.png"
import scribble from "../../images/shop/sixFeet/scribble.png"

import as_left from "../../images/shop/sixFeet/as_img_left.png"
import as_right from "../../images/shop/sixFeet/as_img_right.png"
import pij_left from "../../images/shop/sixFeet/pij_img_left.jpg"
import pij_right from "../../images/shop/sixFeet/pij_img_right.jpg"

import center_placeholder from "../../images/shop/sixFeet/center-placeholder.png"

import logo from "../../images/shop/sixFeet/sixFeetLogo.png"
import logo_mobile from "../../images/shop/sixFeet/logo-mobile.png"
import x from "../../images/shop/sixFeet/x.svg"

import { setOrderModalOpen, updateVariantModal } from "../../state/cart"
import { setParisTheme } from "../../state/sixFeet"

const SixFeetRendered = ({
  mobile,
  orderDetails,
  parisTheme,
  dispatch,
  products,
  productMetaData,
}) => {
  const data = useStaticQuery(graphql`
    query {
      blue_primary: file(relativePath: { eq: "blue_hover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blue_hover: file(relativePath: { eq: "blue_primary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      red_primary: file(relativePath: { eq: "red_hover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      red_hover: file(relativePath: { eq: "red_primary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const params =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : null

  if (params) {
    if (params.get("theme") === "alright") {
      dispatch(setParisTheme(false))
    } else {
      dispatch(setParisTheme(true))
    }
  }

  // let parisToggle = {
  //   backgroundColor: parisTheme ? "#fff" : "#0a20d3",
  //   color: parisTheme ? "#0a20d3" : "#fff",
  // }
  //
  // let alrightToggle = {
  //   backgroundColor: parisTheme ? "#eb2127" : "#fff",
  //   color: parisTheme ? "#fff" : "#eb2127",
  // }

  let handle = `six-feet-shirt-${parisTheme ? "blue" : "red"}`

  let currentProduct = products.filter(i => {
    if (i.handle === handle) {
      return i
    }
  })

  let available = currentProduct[0].availableForSale

  let sizesUnavailable = []
  currentProduct[0].variants.map(i => {
    if (!i.availableForSale) {
      sizesUnavailable.push(i.sku.split("-")[1])
    }
  })

  let cta = (
    <div className="button-container">
      <button
        onClick={() =>
          dispatch(
            updateVariantModal({
              isOpen: true,
              productId: currentProduct[0].handle,
              sizesUnavailable: sizesUnavailable,
            })
          )
        }
        className={`shop-buy-button text-${parisTheme ? "blue" : "alrightRed"}`}
      >
        Buy Now
      </button>
    </div>
  )

  console.log(data)

  let primaryImage = (
    <Img
      fluid={
        parisTheme
          ? data.blue_primary.childImageSharp.fluid
          : data.red_primary.childImageSharp.fluid
      }
    ></Img>
  )

  let hoverImage = (
    <Img
      fluid={
        parisTheme
          ? data.blue_hover.childImageSharp.fluid
          : data.red_hover.childImageSharp.fluid
      }
    ></Img>
  )

  console.log(primaryImage)

  if (!available) {
    cta = (
      <div className="button-container soldout">
        <button className="shop-buy-button">Sold Out</button>
      </div>
    )
  }
  return (
    <Container fluid id="sixFeetPage" className="">
      <Row className="toggle-container">
        <Col className="d-flex justify-content-center">
          <div className={`paris toggle ${parisTheme ? "active" : ""}`}>
            <Link to="/shop/six-feet?theme=paris">
              <img src={parisTheme ? paris_blue : paris_white} alt="" />
            </Link>
          </div>
          <img src={x} alt="" className="x" />
          <div className={`alright toggle ${parisTheme ? "" : "active"}`}>
            <Link to="/shop/six-feet?theme=alright">
              <img src={parisTheme ? alright_white : alright_red} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="logo-row d-flex justify-content-center">
        <Col className="logo-container" xs="11" md={{ size: 12 }}>
          <img src={mobile ? logo_mobile : logo} alt="" />
        </Col>
      </Row>
      {mobile ? (
        ""
      ) : (
        <Row className="charity-disclaimer">
          <Col>
            <p>
              * All proceeds will be donated evenly to the{" "}
              <a href="https://www.foodbanknyc.org/">NY Food Bank</a> and the{" "}
              <a href="https://brooklynbailfund.org/">
                Brooklyn Community Bail Fund
              </a>
            </p>
          </Col>
        </Row>
      )}

      <Row className="content-container">
        <Col
          xs="10"
          md={{ size: 3, offset: 0 }}
          className="left-container d-flex align-items-center"
        >
          <img src={parisTheme ? pij_left : as_left} alt="" />
          {parisTheme ? (
            <img id="world-img" src={world} alt="" />
          ) : (
            <img id="scribble-img" src={scribble} alt="" />
          )}
        </Col>
        <Col>
          {!mobile ? (
            ""
          ) : (
            <Row className="charity-disclaimer">
              <Col>
                <p>
                  * All proceeds will be donated evenly to the{" "}
                  <a href="https://www.foodbanknyc.org/">NY Food Bank</a> and
                  the{" "}
                  <a href="https://brooklynbailfund.org/">
                    Brooklyn Community Bail Fund
                  </a>
                </p>
              </Col>
            </Row>
          )}
        </Col>
        <Col xs="12" md={{ size: 4, offset: 1 }} className="center-container">
          <div className="shop-container">
            {primaryImage}
            <div className="overlay-container">{hoverImage}</div>
            {cta}
          </div>
        </Col>
        <Col
          xs="10"
          md={{ size: 3, offset: 1 }}
          className="right-container d-flex align-items-center"
        >
          <img src={parisTheme ? pij_right : as_right} alt="" />
          {parisTheme ? (
            <img id="chain-img" src={chain} alt="" />
          ) : (
            <img id="tree-img" src={tree} alt="" />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default connect(
  state => ({
    orderDetails: state.cart.orderDetails,
    mobile: state.global.mobile,
    parisTheme: state.sixFeet.parisTheme,
    products: state.products.products,
    productMetaData: state.products.productMetaData,
  }),
  null
)(SixFeetRendered)
